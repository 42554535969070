

// TOOGLE MOBILE NAV
let toogleNav = document.querySelector('.toogle-nav');
let header = document.querySelector('.header');

toogleNav.addEventListener('click', function(){
    header.classList.toggle('openNav')
})


// SHOW SEARCH BAR DESKTOP
// let searchIcon = document.querySelector('.search-box img');
// let searchBar = document.querySelector('.search-box');

// searchIcon.addEventListener('click', function(){
//     searchBar.classList.toggle('searchOpen')
// }) 


// PRODUCT SLIDERS
let slider = document.querySelectorAll('.products-slider');
if(slider){
    for(var s of slider){
        if(window.innerWidth < 992){
            var flkty = new Flickity(s,{
                contain: true,
                pageDots: false,
                groupCells: true,
                wrapAround: true,
                imagesLoaded: true
            })
        }else{
            if(s.querySelectorAll('.products-slider-item').length > 4){
                var flkty = new Flickity(s,{
                    contain: true,
                    pageDots: false,
                    groupCells: true,
                    wrapAround: true,
                    imagesLoaded: true
                })
            }else{
                s.classList.add('sliderRemoved');
            }
        }
    }
}

// PQRS SLIDERS
let sliderPqr = document.querySelector('.pqr-container');
if(sliderPqr){
    if(window.innerWidth < 768){
        var flkty = new Flickity(sliderPqr,{
            contain: true,
            pageDots: false,
            wrapAround: true,
            imagesLoaded: true
        })
    }
}


// HERO HOME
let heroContent = document.querySelector('.hero-banner-container');
if(heroContent){
    var flktyHero = new Flickity('.hero-banner-container', {
        wrapAround: true,
        imagesLoaded: true
    });
    flktyHero.on( 'select', function( index ) {
      if(index == 1 || index == 3){
        flktyHero.element.classList.add('whiteArrows');
        flktyHero.element.classList.remove('purpleArrows');
      }else if(index == 2){
        flktyHero.element.classList.add('purpleArrows');
        flktyHero.element.classList.remove('whiteArrows');
      }else{
        flktyHero.element.classList.remove('purpleArrows');
        flktyHero.element.classList.remove('whiteArrows');
      }
    });
}


// PORTFOLIO BLOCK
let portfItem = document.querySelectorAll('.portfolio-item .main-btn');
let itemInfo = document.querySelectorAll('.portfolio-info .portfolio-info-item, .main-sliders-container .products-slider.slider-block');

if(window.innerWidth > 768){
    for(var p = 0; p < portfItem.length; p++){
        portfItem[p].addEventListener('click', function(e){
            e.preventDefault();
            
            let itemClass = this.parentNode.parentNode.classList[1];
            
            for(var o = 0; o < portfItem.length; o++){
                portfItem[o].parentNode.parentNode.classList.remove('itemActive')
            }
            this.parentNode.parentNode.classList.add('itemActive')
            
            for(var n = 0; n < itemInfo.length; n++){
                if(itemInfo[n].classList.contains(itemClass)){
                    itemInfo[n].style.display = 'flex';
                }else{
                    itemInfo[n].style.display = 'none';
                }
            }
        })
    }
}else{
    let sliderPortfolio = document.querySelector('.portfolio-info.slider-block');
    if(sliderPortfolio){
        var flktyPortfolio = new Flickity(sliderPortfolio,{
            contain: true,
            pageDots: false,
            watchCSS: true
        })

        flktyPortfolio.on( 'select', function( index ){
            let sliderPort = document.querySelectorAll('.main-sliders-container .products-slider');
            
            for(let port of sliderPort){
                if(port !== sliderPort[index]){
                    port.style.display = 'none'
                }else{
                    sliderPort[index].style.display = 'block'
                }
            }
            
            var flkty = new Flickity(sliderPort[index],{
                contain: true,
                pageDots: false,
                groupCells: true,
                wrapAround: true,
                imagesLoaded: true
            })
            flkty.resize();
        })
    }
}


// TABS CATEGORIES
/*let tabItem = document.querySelectorAll('.tabs-nav a');
let heroBlocks = document.querySelectorAll('.category-content .category-hero, .category-content .category-products');
let prodBlocks = document.querySelectorAll('.category-content .category-products');

for(var i of tabItem){
    i.addEventListener('click', function(e){
        e.preventDefault();
        
        var activeClass = this.classList[0];

        for(var j of tabItem){
            j.classList.remove('active')
        }
        this.classList.add('active')

        for(var h of heroBlocks){
            var itemClasses = h.classList;

            if(itemClasses.contains(activeClass)){
                h.style.display = 'block'
            }else{
                h.style.display = 'none'
            }
        }
    })
}*/

let hashUrl = window.location.hash;

if(hashUrl){
    hashUrl = hashUrl.replace('#', '');
    let cateActive = document.querySelectorAll('.tabs-nav a, .category-hero, .category-products');
    let banners = document.querySelectorAll('.category-hero');
    
    for(var n of cateActive){
        if(n.classList.contains(hashUrl)){
            n.classList.add('active');
        }else{
            n.classList.remove('active')
        }
    }
}

// Fijar menú

/* window.onscroll = function() {scrollFunction()};

function scrollFunction() {
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    document.querySelector('.header').classList.add('fixed');
  } else {
    document.querySelector('.header').classList.remove('fixed');
  }
} */





/* MODAL DONDE COMPRAR*/

let openModal = document.querySelectorAll('.stores-item a');
let closeModalBtn = document.querySelectorAll('.modal-close, .modal-back-btn, .modal-continue-btn');
let modal = document.querySelector('.bg-modal-info');
let modalRedirectLink = document.querySelector('.modal-continue-btn');


openModal.forEach(btn => {
    btn.addEventListener('click', function(e){
        e.preventDefault()
        modalRedirectLink.setAttribute('href', e.target.getAttribute('href'))
        modal.classList.toggle('active')
    })
});

closeModalBtn.forEach(btn => {
    btn.addEventListener('click', function(){
        modal.classList.toggle('active')
    })
});

